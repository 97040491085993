import { observable, action, decorate } from 'mobx';
import { BookingService } from '../../services/http';
import { calculateDiscount } from '../../utils/helper';
export const MAX_MB_LIMIT = 256;

export default class ProtectionPlanStore {
    protectionPlans = [];
    selectedPlan = { price: 0 };
    error = '';
    modalError = '';
    history = null;
    showModal = false;
    file = null;
    insuranceProvider = '';
    fileId = null;

    constructor(bookingStore) {
        this.bookingStore = bookingStore;
    }

    init = () => {
        console.log("INIT")
        this.getPlans();
    };

    setFile = (file) => {
        if(file.size > 1048576 * MAX_MB_LIMIT){ // 200MB limit
            console.log("SET SIZE ERROR");
            this.setModalError(`The size of the uploaded file cannot be more than ${MAX_MB_LIMIT} MB.`);
            this.file = null;
            return;
        }
        this.file = file;
        this.modalError = '';
    }

    clearError = () => {
        this.error = '';
    }

    setInsuranceProvider = (text) => {
        console.log(text)
        this.insuranceProvider = text;
        this.modalError = '';
    }

    openModal = () => {
        this.showModal = true;
    }

    setModalError = (text) => {
        console.log("SET MODAL ERROR");
        this.modalError = text;
    }

    closeModal = () => {
        this.showModal = false;
        this.modalError = '';
    }

    getPlans = async () => {
        console.log('getPlans', this.bookingStore?.unitDetails?.id)
        try {
            const protectionPlans = await BookingService.getProtectionPlans(this.bookingStore.unitDetails.id);
            console.log('PROTECTION PLANS', protectionPlans);
            this.protectionPlans = calculateDiscount(protectionPlans);
        } catch (error) { }
    };

    selectPlan = ( planId ) => {
        const selectedPlan = this.protectionPlans.find(plan => plan.id === planId);
        if(selectedPlan?.price !== 0){
            this.file = null;
            this.insuranceProvider = '';
        }
        this.selectedPlan = selectedPlan;
        // this.bookingStore.setSubscriptions(payment);
        this.error = '';
    };

    onSubmit = async () => {
        // const { tenant, unitDetails } = this.bookingStore;
        try {
            if (this.selectedPlan?.price === 0) {
                const formData = new FormData();
                formData.append('file', this.file);
                formData.append('insurance_provider', this.insuranceProvider);
                const uploadInsuranceResponse = await BookingService.uploadInsurance(formData);
                console.log('uploadInsuranceResponse', uploadInsuranceResponse);
                this.fileId = uploadInsuranceResponse.id;
            }
            return true;
        } catch (error) {
            console.log("ERROR", error?.response?.data?.file[0]);
            console.dir(error);
            if(error?.response?.data?.file[0]){
                this.error = error?.response?.data?.file[0];
                return false;
            }
            if(error?.message){
                this.error = error?.message;
                return false;
            }
            this.error = error;
            return false;
        }
    };

    checkForm = () => {
        if(this.selectedPlan?.price === 0){
            if(!this.file || !this.insuranceProvider){
                this.error = 'Please specify your insurance company and/or upload an insurance document file.';
            }
        } else {
            if(!this.selectedPlan) {
                this.error = 'Please choose a protection plan.'
            }
        }
    };

    setHistory = history => {
        this.history = history;
    };
}

decorate(ProtectionPlanStore, {
    protectionPlans: observable,
    selectedPlan: observable,
    error: observable,
    modalError: observable,
    history: observable,
    showModal: observable,
    file: observable,
    insuranceProvider: observable,

    setFile: action,
    getPlans: action,
    selectPlan: action,
    onSubmit: action,
    checkForm: action,
    setHistory: action,
    openModal: action,
    closeModal: action,
    setModalError: action,
    clearError: action,
})
