import logger from './utils/logger';
import avochatoScript from './utils/avochatoScript';
import gaScript from './utils/gaScript';
import gtmFrame from './utils/gtmFrame';
import hotjarScript from './utils/hotjarScript';
import ReactGA from 'react-ga';

export const PROD = process.env.REACT_APP_IS_PROD === 'true';
export const SKIP_DOCUSIGN = false;
const isMobile = window.innerWidth < 768;
const TIMEOUT = 4000;
const MOBILE_TIMEOUT = 5000;
export const STAGE = false;
export const TOKEN = process.env.REACT_APP_API_TOKEN;

export const GOOGLE_API_KEY = 'AIzaSyD03holPLcyW1Rwse-W3AbMUp1DXWZj2l0';
export const BASE_URL = PROD
  ? 'https://admin.localocker.com/'
  : STAGE
  ? 'https://locker-staging-api.westlinkclient.com/'
  : 'https://locker-api.westlinkclient.com/';
export const STRIPE_PUBLISHABLE_KEY = PROD
  ? 'pk_live_51H1wWLGnQbtxmg1ZkJ2P1486nv5ubTGBnCdDzBJiIwPBbahHlbrTVTpLo8JXKNuvTZqOCwnPgRhElffC8vpAXj0G00Tl1v3ym2'
  : STAGE
  ? 'pk_live_51H1wWLGnQbtxmg1ZkJ2P1486nv5ubTGBnCdDzBJiIwPBbahHlbrTVTpLo8JXKNuvTZqOCwnPgRhElffC8vpAXj0G00Tl1v3ym2'
  : 'pk_test_51H1wWLGnQbtxmg1Z2uKdzmgETs4g6T2mmyQbjP5SntT1YI6i0DWktfUhy0NC7j8C24KbCly5PKoqpsw0g6fWdBvg00fYoY8OBn';

export const STRIPE_SECRET_KEY = PROD
  ? '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'
  : STAGE
  ? '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'
  : 'sk_test_51H1wWLGnQbtxmg1Z8ylG04GBONk2E1xC8w60gVOXRCO9feovM80rfa6r7rGI8nW8lAzypmoPDr2H1wi6Zb7w3Ms200M60dOsFW';

export const HOME_PAGE_URL = PROD
  ? 'https://localocker.com/'
  : STAGE
  ? 'https://locker-staging.westlinkclient.com/'
  : 'https://locker.westlinkclient.com/';

if (PROD) {
  window.logger = logger;
  logger.disable();
  ReactGA.initialize('UA-120299096-1', {
    debug: true,
  });
  setTimeout(
    () => {
      // let script = document.createElement('script'); // avochato
      // script.type = 'text/javascript';
      // script.innerHTML = avochatoScript;
      // document.head.appendChild(script);

      let gaHeadScript = document.createElement('script'); // GA
      gaHeadScript.type = 'text/javascript';
      gaHeadScript.innerHTML = gaScript;
      document.head.appendChild(gaHeadScript);

      let noScript = document.createElement('noscript'); // GTM
      noScript.innerHTML = gtmFrame;
      document.body.appendChild(noScript);

      let hotjarHeadScript = document.createElement('script'); // hotjar
      hotjarHeadScript.type = 'text/javascript';
      hotjarHeadScript.innerHTML = hotjarScript;
      document.head.appendChild(hotjarHeadScript);
    },
    isMobile ? MOBILE_TIMEOUT : TIMEOUT
  );
}
