import { observable, action, decorate, reaction, autorun } from 'mobx';
import Field from '../utils/entities/Field';
import validator from '../utils/validator';
import { LocationService, BookingService } from '../services/http';
import BillingStore from './bookingStores/BillingStore';
import bookingStore from './bookingStores/BookingStore';

class PaymentStore {
    paymentOption = 0;
    creditFields = {
        firstName: new Field('text', true),
        lastName: new Field('text', true),
        cardNumber: {
            error: '',
            name: 'cardNumber'
        },
        cardExpiry: {
            error: '',
            name: 'cardExpiry'
        },
        cardCvc: {
            error: '',
            name: 'cardCvc'
        }
    };

    achFields = {
        firstName: new Field('text', true),
        lastName: new Field('text', true),
        bankName: new Field('text', true),
        accountType: new Field('text', true),
        accountNumber: new Field('number', true),
        routingNumber: new Field('number', true)
    };
    cardElement = null;
    stripe = null;
    history = null;
    error = '';
    promocodeField = new Field('text', false);
    promocode = null;
    isFetching = false;
    unitDetails = null;
    subscription = null;
    bookingId = null;
    tenantId = null;
    moveInDate = null;
    disabled = false;
    credit = 0;
    isUpdateCard = false;
    updatePaymentSuccess = false;
    protectionPlan = {};

    setPromocode = (promocode) => {
        this.promocodeField.value = promocode;
    }

    setUnitDetails = (unitDetails) => {
        this.unitDetails = unitDetails;
    }

    setSubscription = (subscription) => {
        this.subscription = subscription;
    }

    setTenantId = (tenantId) => {
        this.tenantId = tenantId;
    }

    setBookingId = (bookingId) => {
        this.bookingId = bookingId;
    }

    setProtectionPlan = (protectionPlan) => {
        this.protectionPlan = protectionPlan;
    }

    setMoveInDate = (moveInDate) => {
        this.moveInDate = moveInDate;
    }

    cardOnChange = event => {
        this.creditFields[event.elementType].error = event.error ? event.error.message : '';
    };
    setPaymentOption = num => {
        this.paymentOption = num;
    };
    setStripe = (stripe, cardElement) => {
        this.stripe = stripe;
        this.cardElement = cardElement;
    };

    setHistory = history => {
        this.history = history;
    };
    removePromocode = () => {
        this.promocodeField.value = '';
        this.promocode = null;
    };
    savePromocode = async () => {
        this.promocodeField.error = '';
        try {
            const response = await BookingService.promocode(
                this.promocodeField.value,
                this.subscription.id,
                this.bookingId
            );
            this.promocode = response;
        } catch (error) {
            // console.log('PROMOCODE ERROR');
            this.promocodeField.error = 'Provided code is not valid';
        }
    };

    init = async () => {
        try {
            const initialData = atob(window.location.href.split('?')[1])
                .split('&')
                .reduce((prev, line) => {
                    prev[line.split('=')[0]] = line.split('=')[1];
                    return prev;
                }, {});

            console.log('initialData', initialData);
            this.isUpdateCard = initialData.update_card === 'true';
            if (!this.isUpdateCard) {
                try {
                    const validateLink = await BookingService.validateLink(initialData.booking_id);
                } catch (err) {
                    if (err.detail) {
                        this.history.push({
                            pathname: 'booking-error',
                            state: { title: err.detail, withoutDescription: true }
                        });
                    } else {
                        this.history.push({
                            pathname: 'booking-error',
                            state: { title: 'Subscription is already paid', withoutDescription: true }
                        });
                    }
                    console.dir(err);
                }
                const location = await LocationService.getLocation(initialData.location_id);
                // console.log("LOCATION", location);
                const size = location.unit_categories.find(el => el.id == initialData.unit_category_id)
                    .size;
                const locationName = location.address;

                const protectionPlans = await BookingService.getProtectionPlans(initialData.unit_id);

                this.protectionPlan = protectionPlans.find(el => el.id == initialData.insurance_id);
                // console.log("this.unitDetails", {
                //     locationName,
                //     size
                // });
                this.unitDetails = {
                    locationName,
                    size
                };
                const subsciptions = await BookingService.getPaymentPlans(initialData.unit_id);
                const currentPayment = subsciptions.find(el => el.id == initialData.unit_price_id);
                this.subscription = currentPayment;
                this.bookingId = initialData.booking_id;
                // console.log('this.moveInDate', initialData.move_in_date || new Date());
                this.moveInDate = initialData.move_in_date || new Date();
                this.credit = initialData.credit || 0;
            }
            this.tenantId = initialData.tenant_id;
        } catch (err) {
            console.log(err);
            bookingStore.billingStore.error =
                'Oops! Something went wrong - the link is used or expired. Contact reserve@localocker.com for help.';
            this.history.push('booking-error');
        }
    };

    checkForErrors = fields => !!Object.keys(fields).filter(field => fields[field].error).length;

    onCardUpdate = async () => {
        const fields = this.paymentOption === 0 ? this.creditFields : this.achFields;

        Object.keys(fields).forEach(
            field =>
                fields[field].required &&
                (fields[field].error = validator.validationField(field, fields[field].value))
        );

        const isErrors = this.checkForErrors(fields);

        let paymentId = null;
        this.disabled = true;

        if (this.paymentOption === 0) {
            const result = await this.stripe.createToken(this.cardElement);
            this.isFetching = true;

            if (result.error) {
                this.isFetching = false;
                this.disabled = false;
                return;
            }
            if (result.token || !isErrors) {
                const paymentResponse = await BookingService.addCardToken(this.tenantId, result.token.id);
                paymentId = paymentResponse.id;
            } else {
                this.isFetching = false;
                this.disabled = false;
                return;
            }
        } else {
            const result = await this.stripe.createToken('bank_account', {
                country: 'US',
                currency: 'usd',
                routing_number: this.achFields.routingNumber.value,
                account_number: this.achFields.accountNumber.value,
                account_holder_name: `${this.achFields.firstName.value} ${this.achFields.lastName.value}`,
                account_holder_type: this.achFields.accountType.value.toLowerCase()
            });
            this.isFetching = true;
            if (!isErrors && result && result.token && result.token.id) {
                try {
                    const paymentResponse = await BookingService.addCardToken(this.tenantId, result.token.id);
                    paymentId = paymentResponse.id;
                } catch (err) {
                    this.isFetching = false;
                    this.disabled = false;

                    return;
                }
            } else {
                this.isFetching = false;
                this.disabled = false;

                return;
            }
        }
        this.history.push('/booking-success');
        this.updatePaymentSuccess = true;
    };

    onSubmit = async () => {
        if (this.isUpdateCard) {
            this.onCardUpdate();
            return;
        }
        const fields = this.paymentOption === 0 ? this.creditFields : this.achFields;

        Object.keys(fields).forEach(
            field =>
                fields[field].required &&
                (fields[field].error = validator.validationField(field, fields[field].value))
        );

        const isErrors = this.checkForErrors(fields);

        let paymentId = null;
        this.disabled = true;

        if (this.paymentOption === 0) {
            const result = await this.stripe.createToken(this.cardElement);
            this.isFetching = true;

            if (result.error) {
                this.isFetching = false;
                this.disabled = false;
                return;
            }
            if (result.token || !isErrors) {
                const paymentResponse = await BookingService.addCardToken(this.tenantId, result.token.id);
                paymentId = paymentResponse.id;
            } else {
                this.isFetching = false;
                this.disabled = false;
                return;
            }
        } else {
            const result = await this.stripe.createToken('bank_account', {
                country: 'US',
                currency: 'usd',
                routing_number: this.achFields.routingNumber.value,
                account_number: this.achFields.accountNumber.value,
                account_holder_name: `${this.achFields.firstName.value} ${this.achFields.lastName.value}`,
                account_holder_type: this.achFields.accountType.value.toLowerCase()
            });
            this.isFetching = true;
            if (!isErrors && result && result.token && result.token.id) {
                try {
                    const paymentResponse = await BookingService.addCardToken(this.tenantId, result.token.id);
                    paymentId = paymentResponse.id;
                } catch (err) {
                    this.isFetching = false;
                    this.disabled = false;

                    return;
                }
            } else {
                this.isFetching = false;
                this.disabled = false;

                return;
            }
        }
        const formData = new FormData();
        formData.append('tenant_id', this.tenantId);
        formData.append('subscription_id', this.subscription.id);
        formData.append('booking_id', this.bookingId);
        formData.append('payment_id', paymentId);
        formData.append('repay', true);

        if (this.promocode !== null) formData.append('promocode_id', this.promocode.id);
        try {
            await BookingService.completeBooking(formData);
            this.history.push('/booking-success');
            this.isFetching = false;
            this.disabled = false;
        } catch (err) {
            this.history.push('/booking-error');
            this.isFetching = false;
            this.disabled = false;

            this.error = err.response.data.error;
        }
    };
}

decorate(PaymentStore, {
    paymentOption: observable,
    creditFields: observable,

    achFields: observable,
    cardElement: observable,
    stripe: observable,
    history: observable,
    error: observable,
    promocodeField: observable,
    promocode: observable,
    isFetching: observable,
    unitDetails: observable,
    subscription: observable,
    bookingId: observable,
    tenantId: observable,
    moveInDate: observable,
    disabled: observable,
    credit: observable,
    isUpdateCard: observable,
    updatePaymentSuccess: observable,

    protectionPlan: observable,

    cardOnChange: action,
    setPaymentOption: action,
    setStripe: action,
    setHistory: action,
    removePromocode: action,
    savePromocode: action,
    init: action,
    onCardUpdate: action,
    onSubmit: action,
})

const paymentStore = new PaymentStore();

export default paymentStore;


