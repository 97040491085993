import { observable, action, decorate } from 'mobx';
import { BookingService } from '../../services/http';
import { calculateDiscount } from '../../utils/helper';
import bookingStore from './BookingStore';

export default class UnitDetailsStore {
    fields = null;
    payments = [];
    selectedPayment = null;
    error = false;
    moveInDate = new Date();
    history = null;
    duration = null;

    constructor(bookingStore) {
        this.bookingStore = bookingStore;
    }

    init = () => {
        console.log("INIT")
        this.getPayments();
    };

    setMoveInDate = date => {
        this.moveInDate = date;
    };

    getPayments = async () => {
        console.log('getPayments', this.bookingStore?.unitDetails?.id)
        try {
            const payments = await BookingService.getPaymentPlans(this.bookingStore.unitDetails.id);
            const sortedPayments = [...payments].sort((a, b) => a.duration - b.duration);
            this.payments = calculateDiscount([sortedPayments[0]]);
        } catch (error) { }
    };

    selectPayment = (payment, duration) => () => {
        this.selectedPayment = payment;
        this.duration = duration;
        this.bookingStore.setSubscriptions(payment);
        this.error = '';
    };

    onSubmit = async () => {
        // const { tenant, unitDetails } = this.bookingStore;

        // try {
        //     const bookingResponse = await BookingService.createBooking({
        //         tenant: tenant.id,
        //         unit: unitDetails.id,
        //         move_in_date: moment(this.moveInDate).format('YYYY-MM-DD'),
        //         payment_plan: this.selectedPayment.id,
        //     });

        //     this.bookingStore.setBookingId(bookingResponse.id);

        //     await BookingService.saveTenantSubscription({
        //         tenant: tenant.id,
        //         subscription: this.selectedPayment.id,
        //         booking_id: bookingResponse.id,
        //     });
        // } catch (error) {
        //     if (error ?.response ?.data ?.unit ?.length) {
        //         bookingStore.billingStore.setError('All available units are already booked.');
        //         this.history.push('/booking-error');
        //     }
        // }
    };

    checkForm = () => {
        !this.selectedPayment && (this.error = 'Please select a payment plan.');
    };

    setHistory = history => {
        this.history = history;
    };
}

decorate(UnitDetailsStore, {
    fields: observable,
    payments: observable,
    selectedPayment: observable,
    error: observable,
    moveInDate: observable,
    history: observable,
    duration: observable,

    setMoveInDate: action,
    getPayments: action,
    selectPayment: action,
    onSubmit: action,
    checkForm: action,
    setHistory: action,
})
