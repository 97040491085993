import http from './http';

export class BookingService {
    static async createBooking(data) {
        return http.post('booking/', data);
    }

    static async uploadInsurance(data) {
        return http.post('insurance/', data);
    }

    static async getPaymentPlans(id) {
        return http.get(`prices/?unit=${id}`);
    }

    static async getProtectionPlans(id) {
        return http.get(`protection/`);
    }

    static async completeBooking(data) {
        return http.post('complete-booking/', data);
    }

    static async saveTenantSubscription(data) {
        return http.post('tenant-subscriptions/', data);
    }

    static async addCardToken(tenantId, token, plaidAccountId=null, type="CARD") {
        return http.post(`payments/?tenant_id=${tenantId}`, {
            token,
            account_id: plaidAccountId,
            type
        });
    }

    static async getDocuSign(tenantId, bookingId) {
        return http.get(`sign?tenant=${tenantId}&booking=${bookingId}`);
    }

    static async promocode(code, subscription, booking) {
        return http.post('promocodes/', { code, subscription, booking });
    }

    static async validateLink(bookingId) {
        return http.post('validate-subscription/', { booking_id: bookingId });
    }

    static async lockUnit(unitId) {
        return http.post('lock-unit/', { unit_id: unitId });
    }

    static async checkUnit(unitId) {
        return http.get(`lock-unit/${unitId}/`);
    }

    static async obtainPlaidLinkToken(tenantId) {
        return http.get(`plaid-link-token/${tenantId}/`);
    }

    static async getBooking(bookingId) {
        return http.get(`booking/${bookingId}/`);
    }
}
