import React, { useEffect } from "react";
import { observer } from "mobx-react";
import BackButton from "../components/BackButton";
import BookingSteps from "../components/BookingSteps";
import { withRouter } from "react-router-dom";
import bookingStore from "../stores/bookingStores/BookingStore";
import Template from "../components/Template";
import locationsStore from "../stores/LocationsStore";
import StorageService from "../services/StorageService";
import { PROD } from "../config";
import ReactGA from "react-ga";

export default withRouter(
  observer(({ history, match }) => {
    const {
      billingStore: {
        savePromocode
      },
      step,
      processFlowFrom4Step,
      nextStep,
      setStep,
      prevStep,
      isFetching,
      getUnitDetails,
      currentStepStore,
      isSuccessComplete,
      unitDetails,
      unitDetailsStore,
      setUnitDetails,
      locationPath,
      setLocationPath,
    } = bookingStore;
    const { getPayments } = unitDetailsStore;

    const init = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const data = Object.fromEntries(urlParams.entries());

      const bookingId = data.booking_id;
      const promocode = data.promocode || '';

      const pathSegments = window.location.pathname.split('/');
      const lastSegment = pathSegments[pathSegments.length - 1];

      if (bookingId && lastSegment === '4') {
        await processFlowFrom4Step(bookingId, promocode, history);
        await savePromocode();
        setStep(4);
        return
      }
      if (window.location.pathname.split("/")[2] === "redirect") {
        bookingStore.setFetching(true);
        try {
          console.log("TRY");
          const data = window.location.href
            .split("?")[1]
            .split("&")
            .map((el) => ({ key: el.split("=")[0], value: el.split("=")[1] }));
          history.replace(`/booking/${step}`);
          await locationsStore.getLocation(data[0].value);
          const response = locationsStore.location;
          const currentCategory = response.unit_categories.find(
            (elem) => elem.id == data[1].value
          );
          const currentUnit = currentCategory.units.find(
            (unit) => unit.id == data[2].value
          );
          if (currentUnit.status === "booked") {
            history.replace({
              pathname: "/booking-error",
              state: {
                title: "Unit is already booked",
                withoutDescription: true,
              },
            });
          } else {
            console.log("Booking Page setUnitDetails");
            console.log("window.location.href", window.location.href);
            bookingStore.setUnitDetails({
              ...currentUnit,
              locationName: response.title,
              locationAddress: response.address,
              size: currentCategory.size,
              key: currentCategory.id,
            });
          }
        } catch (err) {
          console.log(err);
        }
        bookingStore.setFetching(false);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const data = Object.fromEntries(urlParams.entries());
        if (Object.keys(data).length) {
          console.log("if set unit details", data);
          if (data.locationPath) {
            setLocationPath(data.locationPath);
          }
          setUnitDetails(data);
        }
        if (!getUnitDetails(data)) {
          if(PROD){
            return (window.location.href = "https://locations.localocker.com/");
          } else {
            return history.push('/locations');
          }
          // return (window.location.href = "https://locations.localocker.com/");
        }
        console.log("HISTORY REPLACE", step);
        history.replace(`/booking/${step}`);
      }
    };

    useEffect(() => {
      console.log("useEffect");
      init();
      if (PROD) {
        ReactGA.event({
          category: "pageview",
          action: window.location.pathname,
          name: "pageview",
        });
      }

      return () => {
        if (step !== 1 && step !== 2 && step !== 3) {
          currentStepStore &&
            currentStepStore.destroy &&
            currentStepStore.destroy();
        }
      };
      // eslint-disable-next-line
    }, [history, step]);

    const onBackHandler = () => {
      console.log("onBackHandler");
      // const locationPath = StorageService.getItem("locationPath");
      if(PROD){
        if (locationPath) {
          console.log("locationPath", locationPath);
          window.location.href = `https://locations.localocker.com/${locationPath}`;
        } else {
          window.location.href = "https://locations.localocker.com/";
        }
      } else {
        history.push(`/${locationPath}`);
      }

    };

    const expiredBookingCheck = () => {
      const lastTimer = StorageService.getItem("expiredTimer");
      clearTimeout(lastTimer);
      const timer = setTimeout(() => {
        const isBooking = new RegExp("/booking/");
        if (window.location.pathname.match(isBooking)) {
          history.push({
            pathname: "/booking-error",
            state: {
              title: "Booking process session has expired.",
              withoutDescription: true,
            },
          });
        }
      }, 1000 * 60 * 60); // 60 min
      StorageService.setItem("expiredTimer", timer);
    };

    useEffect(() => {
      expiredBookingCheck();
    }, []);

    useEffect(() => {
      getPayments();
    }, [unitDetails]);

    return (
      <Template>
        <div className="booking-page">
          <BackButton text="Back to Location page" onClick={onBackHandler} />
          <BookingSteps
            match={match}
            nextStep={nextStep}
            prevStep={prevStep}
            isFetching={isFetching}
            isSuccessComplete={isSuccessComplete}
          />
        </div>
      </Template>
    );
  })
);
