import React, { useEffect } from "react";
import { observer } from "mobx-react";
import BackButton from "../components/BackButton";
import { SuccessIcon } from "../assets/icons";
import { ErrorIcon } from "../assets/icons";
import { Button } from "../components/Button";
import { useHistory } from "react-router-dom";
import locationsStore from "../stores/LocationsStore";
import bookingStore from "../stores/bookingStores/BookingStore";
import Template from "../components/Template";
import parseStrWithLink from "../utils/parseStrWithLink";
import StorageService from "../services/StorageService";
import { HOME_PAGE_URL } from "../config";
import paymentStore from "../stores/paymentStore";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { PROD } from "../config";

export default observer((props) => {
  const history = useHistory();
  const routeLocation = useLocation();
  const { location } = locationsStore;
  const { locationPath } = bookingStore;
  const { error, paymentSuccess } = bookingStore.billingStore;
  const { updatePaymentSuccess } = paymentStore;
  const currentPath = window.location.pathname.split("-")[1];

  useEffect(() => {
    if (PROD) {
      ReactGA.event({
        category: "pageview",
        action: window.location.pathname,
        name: "pageview",
      });
    }
  }, []);
  const config = {
    success: {
      icon: <SuccessIcon />,
      title: updatePaymentSuccess
        ? "Thank you!"
        : paymentSuccess
        ? "Sign the agreement to receive access to your storage unit"
        : "Thank you! The booking is confirmed.",
      description: updatePaymentSuccess
        ? "Your payment method has been updated successfully."
        : paymentSuccess
        ? {
            isText: true,
            text: ["Your payment is successful!"],
          }
        : {
            isText: true,
            text: [
              "Check your email – Your access info and other need‑to‑knows are on their way.",
              "If you don't receive an email within 5 minutes - please check your Spam folder.",
            ],
          },
      buttonClass: "continue",
      buttonText: paymentSuccess ? "Sign agreement" : "Ok",
      onClick: paymentSuccess
        ? () => {
            history.replace(`/booking/5`);
          }
        : () => {
            window.location.href = HOME_PAGE_URL;
          },
    },
    error: {
      icon: <ErrorIcon />,
      title:
        routeLocation?.state?.title ||
        "Sorry we could not complete your booking.",
      description: routeLocation?.state?.withoutDescription
        ? ""
        : error
        ? parseStrWithLink(error)
        : "The payment for this transaction has failed.",
      buttonClass: "back",
      buttonText: "Back",
      onClick: () => {
        if (routeLocation?.state?.title === "Subscription is already paid") {
          window.location.href = HOME_PAGE_URL;
          return;
        }
        if (
          routeLocation?.state?.title ===
            "Booking process session has expired." ||
          (error && error.match(/All available/gi))
        ) {
          if(PROD){
            locationPath
              ? (window.location.href = `https://locations.localocker.com/${locationPath}`)
              : (window.location.href = "https://locations.localocker.com/");
          } else {
            locationPath ? history.push(`/${locationPath}`) : history.push('/locations')
          }
        } else {
          bookingStore.step = 4;
          history.goBack();
        }
      },
    },
  };
  const currentConfig = config[currentPath];
  const goToLocationPage = () => {
    location ? history.push(`/${location.id}`) : history.push("/");
  };

  return (
    <Template>
      <div className="final-page">
        <BackButton text="Back to Location page" onClick={goToLocationPage} />
        <div className="final-window">
          {currentConfig.icon}
          <h3>{currentConfig.title}</h3>
          {currentConfig.description.isText ? (
            currentConfig.description.text.map((str, index, arr) => (
              <p
                style={{
                  marginBottom: index === arr.length - 1 ? "30px" : "15px",
                }}
              >
                {str}
              </p>
            ))
          ) : (
            <p>{currentConfig.description}</p>
          )}

          <Button
            text={currentConfig.buttonText}
            addClass={`booking-navigation-btn ${currentConfig.buttonClass}`}
            onClick={currentConfig.onClick}
          />
        </div>
      </div>
    </Template>
  );
});
