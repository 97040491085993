import React, { useEffect } from "react";
import { Button } from "../Button";
import DataPicker from "../DatePicker";

import { NavigatorIcon } from "../../assets/icons";
import PaymentCard from "../PaymentCard";
import { observer } from "mobx-react";
import parseSize from "../../utils/parseSize";
import classNames from "classnames";
import bookingStore from "../../stores/bookingStores/BookingStore";
import { useHistory } from "react-router-dom";

export default observer(({ nextStep }) => {
  const {
    error,
    payments,
    checkForm,
    moveInDate,
    setMoveInDate,
    selectPayment,
    selectedPayment,
    setHistory,
  } = bookingStore.unitDetailsStore;
  const { unitDetails } = bookingStore;
  const { locationAddress, size } = unitDetails;
  const history = useHistory();

  useEffect(() => {
    setHistory(history);
  }, []);

  useEffect(() => {
    if (payments.length > 0 && !selectedPayment) {
      selectPayment(payments[0], payments[0].duration)();
    }
  }, [payments, selectedPayment]);

  const onNextHandler = (event) => {
    checkForm();
    nextStep(event);
  };

  console.log("RENDER UNIT DETAILS STEP");

  const paymentClassName = classNames("payment-plan", { error: !!error });

  return (
    <div>
      <div className="step-container second">
        <div className="unit-details">
          <div className="title">Unit details</div>
          <div className="unit-info">
            <div className="address">
              <NavigatorIcon className="navigation-icon" /> {locationAddress}
            </div>
            <div className="size">{parseSize(size)}</div>
            <div className="move-date">
              <DataPicker
                label="Select Move-in Date"
                date={moveInDate}
                setDate={setMoveInDate}
              />
            </div>
          </div>
        </div>
        <div className={paymentClassName}>
          <div className="title">Payment Plan</div>
          <div className="payment-cards">
            {payments
              .sort((a, b) => a.duration - b.duration)
              .map((payment, index) => (
                <PaymentCard
                  key={index}
                  duration={payment.duration}
                  price={payment.base_price}
                  threeMonthMinimumRequired={
                    payment.three_minimum_months_required
                  }
                  prepay={
                    payment.duration === 1
                      ? payment.three_minimum_months_required
                        ? "3 months minimum"
                        : ""
                      : `prepay ${payment.duration} months, save $${payment.discount}`
                  }
                  isSelected={payment.id === selectedPayment?.id}
                  onClick={() => {}}
                />
              ))}
          </div>
          <div className="error-msg">{error}</div>
        </div>
      </div>
      <div className="navigation-btns">
        <Button
          text="Continue"
          addClass="booking-navigation-btn continue"
          onClick={onNextHandler}
        />
      </div>
    </div>
  );
});
